import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { getSuspendData } from '@/api/layouts'
const padding = { suspense: false }
export const useLayoutStore = defineStore('layout', () => {
  const footerType = ref(1)
  const suspense = ref(null)
  function setFooterType (value) {
    footerType.value = value
  }
  async function setSuspense () {
    if (suspense.value) return
    if (padding.suspense) return
    padding.suspense = true
    const res = await getSuspendData()
    padding.suspense = false
    if (res) {
      suspense.value = res
    }
  }
  return {
    footerType,
    setFooterType,
    suspense,
    setSuspense
  }
})
if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot)) }
